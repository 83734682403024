
  import { mapGetters } from 'vuex';

  export default {
    inject: ['$validator'],
    components: {
      SigninWithSSO: () => import('./SigninWithSSO'),
      PasswordInput: () => import('../PasswordInput'),
    },
    data() {
      return {
        user: {
          email: '',
          password: '',
        },
      };
    },
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
      logoUrl: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        required: false,
      },
      allowAccountCreation: {
        type: Boolean,
        default: false,
      },
      mainLabel: {
        type: String,
      },
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
    },
    methods: {
      async login() {
        await this.$validator.validateAll();
        if (this.formIsInvalid) return;
        const userData = { ...this.user };
        if (this.storeInformation.allowSignInWithEmail) {
          userData.password = userData.email;
        }
        this.$emit('login', userData);
      },
    },
  };
